<template>
    <div>
        <div class="mains" v-if="resetLoading" v-loading="resetLoading" element-loading-spinner="el-icon-loading"></div>
        <div class="reset-wrapper" v-else>
            <div class="breadcrumb">
                <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/cycleUndetectedReset' }">漏检重置记录</el-breadcrumb-item>
                <el-breadcrumb-item>状态重置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="reset-middle">
                <div class="pub-title">日期筛选<span style="color:red;">*</span></div>
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="formInline.date"
                            style="width: 215px"
                            value-format="timestamp"
                            type="date"
                            size="medium"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="medium" @click="findCyclePlan">选择计划</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="reset-middle">
                <div class="pub-title">重置计划<span style="color:red;">*</span></div>
                <div v-if="selectPatrolData.length>0">
                    <div class="patrol-content" v-for="(item,index) in selectPatrolData" :key="index">
                        <div class="patrol-title">
                            <span>{{ item.taskRuleCode }}</span>
                            <i class="el-icon-close" @click="close(index)"></i>
                        </div>
                        <div class="patrol-name">
                            <div class="patrol-item" v-for="(pItem,pIndex) in item.patrolList" :key="pIndex">
                                {{ pItem.patrolName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="tips">
                    <div class="tips-icon">!</div>
                    <div class="tips-txt">未选择计划</div>
                </div>
            </div>
            <div class="reset-middle">
                <div class="pub-title">漏检理由<span style="color:red;">*</span></div>
                <el-form>
                    <el-form-item label="">
                        <el-input v-model="reason" type="textarea" :rows="5" placeholder="请填写漏检理由" maxlength="300" show-word-limit @input="changeInput"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="bottom-btn">
                <el-button type="primary" size="small" @click="$router.push('/cycleUndetectedReset')">取消</el-button>
                <el-button type="primary" size="small" @click="onSubmit">提交</el-button>
            </div>
            <!-- 巡视点侧滑 -->
            <el-drawer
                title="添加重置计划"
                :with-header="false"
                :visible.sync="patrolDrawer"
                direction="rtl"
                size="1000px">
                <div class="drawer-title">添加重置计划</div>
                <div class="drawer-content table-tree">
                    <div class="tree" v-loading="taskLoading" element-loading-spinner="el-icon-loading">
                        <div class="tree-title">巡检计划</div>
                        <el-tree
                        :data="departmentTreeList"
                        node-key="id"
                        @node-click="taskClick"
                        :props="patrolProps"
                        :default-checked-keys="[1]"
                        highlight-current
                        :current-node-key="taskRuleId"
                        ref="patrolTree">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span style="margin-right: 40px">{{ node.label }}</span>
                            <span :style="data.missCount>0?'color:#ff7675;':''">【漏检数量 {{ data.missCount }}】</span>
                        </span>
                        </el-tree>
                    </div>
                    <div class="table">
                        <el-table :data="tableDataCp" height="700" v-loading="patrolLoading" element-loading-spinner="el-icon-loading">
                        <el-table-column label="勾选" width="70">
                            <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="patrolCancel">取消</el-button>
                    <el-button size="small" type="primary" @click="patrolDrawerOK" class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
export default {
    data() {
        return {
            taskLoading: false,
            patrolLoading:false,
            resetLoading: false,
            formInline: {},
            pickerOptions: {
                disabledDate: time => {
                let d = dayjs(time).format("YYYYMMDD").valueOf();
                let n = dayjs().format("YYYYMMDD").valueOf();
                return d > n;
                }
            },

            reason: null,
            patrolDrawer: false,
            taskRuleId: null,
            taskRuleCode: null,
            departmentTreeList: [],
            tableDataCp:[],
            tableSelectList: [],
            selectPatrolData: [],
            patrolProps: {
                children: 'childTree',
                label: 'taskCode'
            },
        }
    },
    methods: {
        dayjs,
        onSubmit() {
            if(this.selectPatrolData.length==0) {
                return this.$message.error('请选择需要重置的计划')
            }
            if(!this.reason) {
                return this.$message.error('请填写漏检理由')
            }
            let todayTime = new Date(new Date().toLocaleDateString()).getTime()
            let taskRules = []
            this.selectPatrolData.forEach(v=>{
                let patrols = []
                v.patrolList.forEach(p=>{
                    patrols.push({
                        patrolId: p.patrolId,
                        patrolName: p.patrolName,
                    })
                })
                taskRules.push({
                    taskCode: v.taskRuleCode,
                    taskRuleId: v.taskRuleId,
                    patrols: patrols,
                })
            })
            let form = {
                taskCreateTime: this.formInline.date?this.formInline.date:todayTime,
                taskRules: taskRules,
                modifyRemarks: this.reason,
            }
            this.resetLoading = true
            this.$ajax.post("rulemodifyTaskStatus", form).then((res) => {
                if(res.code==0) {
                    this.resetLoading = false
                    this.$message.success("重置成功");
                    this.$router.push("/cycleUndetectedReset");
                }else {
                    this.resetLoading = false
                    this.$message.error("重置失败");
                }
            }).catch((err)=>{
                this.resetLoading = false
                this.$message.error("重置失败");
            })
        },
        taskClick(data){
            this.selectPatrolData.forEach(v=>{
                if(v.taskRuleId==data.id) {
                    this.tableSelectList = v.patrolList
                }else {
                    this.tableSelectList = []
                }
            })
            this.queryCycleFilterDetail(data.id,data.taskCode)
        },
        // 点击查询巡检计划按钮
        findCyclePlan() {
            this.patrolDrawer = true
            this.queryCycleFilterList();
        },
        patrolCancel() {
            this.patrolDrawer = false
        },
        patrolDrawerOK(){
            this.patrolDrawer = false
            let inIs = this.selectPatrolData.every((obj)=> {
              return obj.taskRuleId != this.taskRuleId
            })
            if (inIs) {
                if(this.tableSelectList.length>0) {
                    this.selectPatrolData.push({
                        taskRuleId: this.taskRuleId,
                        taskRuleCode: this.taskRuleCode,
                        patrolList: this.tableSelectList
                    })
                }
            }else {
                this.selectPatrolData.forEach(item => {
                    if(item.taskRuleId==this.taskRuleId) {
                        item.patrolList = this.tableSelectList
                    }
                })
            }
            this.tableSelectList = []
        },
        // 勾选多个巡视点
        checkeChange(row) {
            this.selectPatrolData.forEach(se => {
                if(se.taskRuleId==this.taskRuleId) {
                    this.tableSelectList = se.patrolList
                }
            })
            if (row.checked) {
                // 勾选
                this.tableSelectList.push(row)
            } else {
                this.tableSelectList.forEach((item, index) => {
                    if (item.patrolId == row.patrolId) {
                        this.tableSelectList.splice(index, 1)
                    }
                })
                this.selectPatrolData.forEach((se,index) => {
                    if(se.taskRuleId==this.taskRuleId) {
                        if(this.tableSelectList.length==0&&se.patrolList.length==0) {
                            this.selectPatrolData.splice(index, 1)
                        }
                    }
                })
            }
        },
        close(index) {
            this.selectPatrolData.splice(index, 1)
            if(this.selectPatrolData.length==0) {
                this.selectPatrolData = []
            }
        },
        // 文本框事件
        changeInput(e) {},
        // 查询巡检计划
        queryCycleFilterList() {
            this.taskLoading = true
            this.patrolLoading = true
            let todayTime = new Date(new Date().toLocaleDateString()).getTime()
            this.$ajax.post("queryCycleFilterList", {
                taskCreateTime: this.formInline.date?this.formInline.date:todayTime
            }, {
                taskType: 0
            }).then((res) => {
                this.departmentTreeList = res.data;
                this.taskRuleId = res.data[0].id
                this.taskRuleCode = res.data[0].taskCode
                this.taskClick(res.data[0])
                this.taskLoading = false
                this.$nextTick(()=>{
                    this.$refs.patrolTree.setCurrentKey(res.data[0].id)
                })
            }).catch(err=>{
                this.taskLoading = false
                this.patrolLoading = false
            })
        },
        // 查询巡检计划下所有巡视点
        queryCycleFilterDetail(taskRuleId,taskCode) {
            this.patrolLoading = true
            this.taskRuleId = taskRuleId
            this.taskRuleCode = taskCode
            this.$ajax.post("queryCycleFilterDetail", {
                taskRuleId: taskRuleId
            }).then((res) => {
                let arr = []
                res.data.forEach(v => {
                    v.checked = false
                    arr.push(v)
                })
                this.tableDataCp = arr
                this.tableDataCp.forEach((v)=>{ 
                    this.selectPatrolData.forEach(s=>{
                        if(s.taskRuleId==this.taskRuleId) {
                            if (s.patrolList.find(item => item.patrolId == v.patrolId)) {
                                v.checked = true
                            }else {
                                v.checked = false
                            }
                        }
                    })
                })
                this.patrolLoading = false
            }).catch(err=>{
                this.patrolLoading = false
            })
        }
    },
    mounted() {
        this.queryCycleFilterList()
    }
}
</script>
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.table-tree {
  display: flex;
  height: 700px;
  margin-top: 20px;
  .tree {
    flex: 1;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
    height: 700px;
    overflow: auto;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.reset-wrapper {
    max-width: 890px;
    position: relative;
    padding-bottom: 100px;
}
.reset-middle {
    width: 100%;
    margin: 30px auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.pub-title {
    font-size: 16px;
    color: #202020;
    font-weight: bold;
    margin-bottom: 20px;
    border-left: 4px solid #409EFF;
    padding-left: 10px;
    span {
        margin-left: 5px;
    }
}
.tips {
    background-color: #F8F9FB;
    height: 250px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tips-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffeecc;
    color: #FAAD14;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
}
.tips-txt {
    color: #636e72;
    font-size: 14px;
    margin: 10px 0;
}
.patrol-content {
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    overflow: hidden;
}
.patrol-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    margin-bottom: 10px;
    background-color: #f0f5f6;
    color: #636e72;
    padding: 10px 20px;
    i {
        color: #636e72;
        cursor: pointer;
        font-size: 20px;
    }
}
.patrol-name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 20px;
}
.patrol-item {
    background-color: #e6f4ff;        
    border-radius: 5px;
    padding: 10px;
    margin: 0px 10px 5px 0;
    font-size: 14px;
    color: #1494fb;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>